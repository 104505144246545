<template>
  <div class="container">
    <div
      class="
        card
        theme-card
        border-lg-0
        p-5
        col-12 col-md-10 col-lg-8
        offset-md-1 offset-lg-2
      "
    >
      <h1 class="mb-5">{{ id ? "Edit" : "Add" }} Profession</h1>
      <form @submit.prevent="addProfession" validate>
        <input-control
          v-model="name"
          type="horizontal"
          :required="true"
          labelClass="col-md-3"
          >Name</input-control
        >

        <div class="form-group row">
          <label class="col-form-label col-md-3">HCAI Profession?</label>
          <div class="col">
            <div class="custom-control custom-switch">
              <input
                type="checkbox"
                class="custom-control-input"
                id="hcai_active"
                v-model="hcai_active"
              />
              <label
                class="custom-control-label pointer-cursor"
                for="hcai_active"
                >{{ hcai_active ? "Yes" : "No" }}</label
              >
            </div>
          </div>
        </div>
        <input-control
          v-model="hcai_name"
          type="horizontal"
          :required="true"
          v-if="showHcaiName"
          labelClass="col-md-3"
          >HCAI Name</input-control
        >
        <input-control
          v-model="code"
          type="horizontal"
          :required="true"
          labelClass="col-md-3"
          >Code</input-control
        >
        <div class="row mb-3">
          <div class="col-9 offset-md-3">
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                v-model="practitioner"
                class="custom-control-input"
                id="prac"
              />
              <label class="custom-control-label" for="prac"
                >Practitioner</label
              >
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                v-model="regulated"
                class="custom-control-input"
                id="reg"
              />
              <label class="custom-control-label" for="reg">Regulated</label>
            </div>
          </div>
        </div>
        <save
          :saving="professions.isSaving"
          classes="save-button btn btn-theme mt-4"
          >Save</save
        >
      </form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Create",
  computed: {
    ...mapState(["professions"]),
    id: function () {
      return this.$route.params.id;
    },
    showHcaiName: function () {
      return this.hcai_active;
    },
  },
  mounted() {
    if (this.id) {
      if (!this.professions || !(this.professions.data || []).length) {
        this.getProfessions().then((professions) => {
          if (professions && professions.length) {
            const profession = professions.filter(
              (prof) => prof.id == this.id
            )[0];
            if (profession) {
              this.name = profession.name;
              this.hcai_name = profession.hcai_name;
              this.hcai_active = !!profession.hcai_name;
              this.code = profession.code;
              this.practitioner = profession.isPractitioner;
              this.regulated = profession.isRegulated;
            }
          }
        });
      } else {
        const profession = this.professions.data.filter(
          (prof) => prof.id == this.id
        )[0];
        if (profession) {
          this.name = profession.name;
          this.code = profession.code;
          this.hcai_name = profession.hcai_name;
          this.hcai_active = !!profession.hcai_name;
          this.practitioner = profession.isPractitioner;
          this.regulated = profession.isRegulated;
        }
      }
    }
  },
  data() {
    return {
      name: null,
      hcai_name: null,
      code: null,
      hcai_active: false,
      practitioner: false,
      regulated: false,
    };
  },
  methods: {
    ...mapActions({
      saveProfession: "professions/create",
      editProfession: "professions/update",
      getProfessions: "professions/all",
    }),
    addProfession: function () {
      let data = {
        name: this.name,
        hcai_name: this.hcai_name,
        code: this.code,
        practitioner: this.practitioner,
        regulated: this.regulated,
      };
      if (!this.hcai_active) {
        data.hcai_name = null;
      }
      if (!this.id) {
        this.saveProfession(data)
          .then((res) => {
            if (res) {
              this.$router.push({ name: "professions" });
            }
          })
          .catch(() => {
            this.saving = false;
          });
      } else {
        data = {
          ...data,
          id: this.id,
        };
        this.editProfession(data)
          .then((res) => {
            if (res) {
              this.$router.push({ name: "professions" });
            }
          })
          .catch(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>
